import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
// import HomeServices from '../../components/HomeServices';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './commercialcorporatelaw.css';

const LowEFilms = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Low E Films" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Commercial <Link to="/services"></Link> / Low E Films
                </p>
            </PageHeader>
            <section className='services'>

                <div className="bird-control-container container">
                    <div className="bird-control-content-container">
                        <div className="bird-control-content">
                            <h2>
                                Low E Films
                            </h2>
                            <br />
                            <p>
                                Heat rejection can lead to significant energy savings on your utility bills, cutting down cooling expenses by as much as 30%, particularly during  sweltering summers. Provide relief to both your cooling system and your finances with Sun Control Window Films. 
                            </p>
                            <p>
                                Maintain a cool and comfortable environment throughout the day by blocking out up to 78% of the sun's heat entering through your windows.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default LowEFilms
import {useEffect} from 'react';
import Card from '../UI/Card';
import {MdSupportAgent} from 'react-icons/md';
import {BsEnvelope} from 'react-icons/bs';
import {HiOutlineLocationMarker} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../pages/home/home.css';

const ContactCta = () => {

useEffect(() => {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
      delay: 50, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-cubic', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className='contact_cta'>
      <div className='container contact_cta_container'>
        <div data-aos="fade-up" className="contact_cta_header">
            <h4>NEED HELP?</h4>
            <h2>We'd Love to Hear From You</h2>
            <p>Contact us today for a consultation and let us help you take back your rights.</p>
        </div>
        <div className="contact_cta_cards">
          <div data-aos="slide-right" >
            <Card className="contact_card">
              <MdSupportAgent className='contact_cta_icon contact_cta_icon_one'/>
                <h3>Contact Us</h3>
                <p>If you have any questions, comments, or concerns, please don't hesitate to contact us.</p>
                <Link to={'https://wa.link/wmyge5'}  target='_blank' className='btn sm contact-cta-btn'>Chat Us</Link>
            </Card>
          </div>
          <div data-aos="fade-up" >
            <Card className="contact_card">
              <HiOutlineLocationMarker className='contact_cta_icon'/>
                <h3>Location</h3>
                <ul>
                  <li>
                    <p>Nyaku House, 1st Floor - Room 18,</p>
                  </li>
                  <li>
                    <p>Argwings Kodhek Road,</p>
                  </li>
                  <li>
                    <p>Nairobi, Kenya.</p>
                  </li>
                </ul>
            </Card>
          </div>
          <div data-aos="slide-left" >
            <Card className="contact_card">
              <BsEnvelope className='contact_cta_icon'/>
                <h3>Email Us</h3>
                <p>We look forward to hearing from you.</p>
                <Link to="./contact" className='btn sm contact-cta-btn'>Email Us</Link>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCta
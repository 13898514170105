import ScrollToTop from '../../components/ScrollToTop';
import Homehero from '../../components/Homehero';
import HomePageHeader from '../../components/HomePageHeader';
import SquareUpAppointments from '../../components/SquareUpAppointments';
import CTA from '../../components/CTA';
import Homeintro from '../../components/Homeintro';
import HomeCarouselServices from '../../components/HomeCarouselServices';
import PracticeAreaIntro from '../../components/PracticeAreaIntro';
import TintingBenefits from '../../components/TintingBenefits';
// import AboutSection from '../../components/AboutSection';
// import ApproachSection from '../../components/Approach';
// import HomeServices from '../../components/HomeServices';
// import HomeServicesTwo from '../../components/HomeServicesTwo';
// import SafteryAssurance from '../../components/SafteryAssurance';
// import WhyUs from '../../components/WhyUs';
// import Testimonial from '../../components/Testimonial';
import ContactCta from '../../components/ContactCta';
import '../../index.css';
import './home.css';


const Home = () => {
    return (
        <>
            <ScrollToTop/>
            <Homehero/>
            {/* <HomePageHeader /> */}
            {/* <CTA /> */}
            {/* <Homeintro/> */}
            <PracticeAreaIntro/>
            <TintingBenefits/>
            <HomeCarouselServices/>
            <SquareUpAppointments/>
            {/* <AboutSection /> */}
            {/* <ApproachSection /> */}
            {/* <HomeServices />
            <HomeServicesTwo />
            <SafteryAssurance/> */}
            {/* <WhyUs /> */}
            {/* <Testimonial /> */}
            {/* <ContactCta /> */}
        </>
    )
}

export default Home
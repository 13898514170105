import React from 'react'
import {benefitsCarousel} from '../data';
import Card from '../UI/Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './tintingbenefits.css';

const TintingBenefits = () => {

    const settings = {
        dots: false,
        autoplay: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className="benefits-main">
            <div className="benefits-cont">
                <div className="benefits-cont-1">
                    <div className="b-cont-1a">
                        <p>Our Services</p>
                        <h2>Our Windows Tinting Services Plans Are Tailor Made To Meet All Your Demands</h2>
                        <div className="cont-1-dec-border"></div>
                    </div>
                    <div className="b-cont-1b">
                        At FD window tint we're incredibly flexible, friendly and easy to work with. Our list of services are widely acknowledged to assure you get the best quality tinting services; Anytime. Anywhere!
                        We offer quality products that are sure to meet the needs of your automotive, commercial or residential building. These include: 
                    </div>
                </div>
                <div className="benefits-cont-2">
                    <div className="benefits-icons-main">
                        <div className="benefits-icon">
                            <Slider {...settings}>
                                {
                                    benefitsCarousel.map(({id, icon, name})=>{
                                        return <Card key={id} className="benefits-card">
                                            <div className="benefits-icon-cont">
                                                <div className="benefit-icon">
                                                    {icon}
                                                </div>
                                            </div>
                                            <div className="benefits-icon-text">
                                                <p>{name}</p>
                                            </div>
                                        </Card>
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TintingBenefits
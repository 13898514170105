import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/anti-graffiti.webp';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
// import HomeServices from '../../components/HomeServices';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './commercialcorporatelaw.css';

const AntiGraffitiFilms = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Anti-Graffiti Films" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Commercial <Link to="/services"></Link> / Anti-Graffiti Films
                </p>
            </PageHeader>
            <section className='services'>

                <div className="bird-control-container container">
                    <div className="bird-control-content-container">
                        <div className="bird-control-content">
                            <div className="antigraffiti-desc">
                                <h1>
                                    Anti-Graffiti Films
                                </h1>
                                <br />
                                <p>
                                    Keep your property safe from vandalism with anti-graffiti films. Investing in Anti-Graffiti Film is a smart choice, as it offers cost savings through its convenient removal and replacement features.
                                </p>
                                <br />
                                <h1>
                                    Where is Anti-graffiti Film Used?
                                </h1>
                                <br/>
                                <p>
                                    Public Transport buses, subway cars etc, commercial store front windows, shopping malls, restrooms, lobbies and elevators are all prime targets for abuse. 
                                </p>
                                <br/>
                                <h1>
                                    Avoid Repairs, Cleanup and Damage with Anti-Graffiti Film
                                </h1>
                                <br />
                                <p>When you think of graffiti, spray paint is probably what comes to mind. Unfortunately, taggers have evolved. Now they also use etching tools, acid, or even permanent markers. Anti-Graffiti Film is a way to fight back. It’s a nearly invisible layer of sacrificial film that protects against multiple types of tagging.</p>

                                <p>This films are engineered specifically to help protect smooth surfaces, such as glass and stainless steel. It’s durable, yet quick for professional installers to remove and replace. When they pull the film away, graffiti damage comes with it. And with installation of new film, your property is once again ready to make a good impression – and stand up to troublesome taggers.</p>

                                <p>You can have anti-graffiti film removed and re-installed many times over before it reaches the same cost as replacement of a single retail window. This makes it an excellent way to help prevent permanent damage to storefronts, public spaces, bathroom mirrors, elevator interiors and more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default AntiGraffitiFilms
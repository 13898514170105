import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/windows-tinting3.webp';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import Carsimulator from '../../components/Carsimulator.jsx';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigationDisputeResolution.css';

const AutomativeWindowTinting = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Automative Window Tinting" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Automative
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <div className="automative-desc">
                            <p>
                                Automotive Window Tint plays a crucial role in maintaining the integrity of your car's interior and contributing to the vehicle's overall long-term value. It enhances the comfort within your car by reducing glare and regulating temperature, offering advantages in both summer and winter seasons. Additionally, window films serve to enhance the visual aesthetics of your car and heighten the level of privacy inside the vehicle.
                                <br/>
                                <b style={{color: 'black', fontWeight: 500}}>Benefits of automotive window tinitng include:</b>
                            </p>
                            <br/>
                            <ol>
                                <li>
                                    <p>
                                    1.	UV Ray Protection: Tinted windows block a significant amount of harmful UV rays, safeguarding the car's interior and reducing the risk of fading, cracking, or discolouration of upholstery and dashboard.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    2.	Temperature Regulation: Tinted windows help regulate the temperature inside the car, making it more comfortable by reducing heat buildup during sunny days and minimizing heat loss in colder weather.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    3.	Glare Reduction: Tinted windows reduce glare from sunlight, headlights, and other reflective surfaces, enhancing visibility and reducing eyestrain for the driver and passengers.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    4.	Privacy: Tinted windows provide increased privacy for occupants and valuables inside the car, preventing prying eyes from easily seeing into the vehicle.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    5.	Enhanced Aesthetics: Car window tinting can enhance the overall appearance of the vehicle, giving it a sleek and stylish look.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    6.	Safety and Security: In the event of an accident, window tinting film can help hold shattered glass together, reducing the risk of injuries from flying glass shards. It can also act as a deterrent against theft by making it more difficult for potential thieves to see inside the car.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    7.	Interior Preservation: Tinted windows help protect the car's interior from sun damage, preserving the quality and lifespan of upholstery, leather, and other materials.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    8.	Health Protection: Reduced exposure to UV rays through tinted windows can contribute to better skin protection for the occupants, minimizing the risk of sun-related skin damage.
                                    </p>
                                </li>
                            </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <Carsimulator/>

            </section>
        </m.div>
    )
}

export default AutomativeWindowTinting
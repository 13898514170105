import { useEffect } from "react";
import { Link } from "react-router-dom";
import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillTwitterSquare} from 'react-icons/ai';
import {FaWhatsappSquare} from 'react-icons/fa';
import YearDateUpdate from "./YearDateUpdate";
import Logo from '../images/FD-window-tint-logo.png';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container footer_container">
                <div className="footer_details_cont">
                    <div className="footer_detail footer_detail_one">
                        <div className="footer_about">
                        <h3>About Us</h3>
                            <p>
                                Investing in window tinting services is a wise decision that can significantly enhance your home, office, or car. Whether you're looking for solar control, security films and much more, we provide top-notch products tailored to your specific tinting requirements.
                            </p>
                        </div>
                    </div>
                    <div className="footer_detail footer_detail_two">
                        <h3>Get In Touch</h3>
                        <ul>
                            <li style={{fontWeight: 300}}>+1 (509) 991-9958</li>
                            <li style={{fontWeight: 300}}>fdwindowtint@gmail.com</li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_three footer-location-desc">
                        <h3>Business Hours</h3>

                        <ul>         
                            <li className="business-hours"><strong>Monday:</strong> 8:00AM-6:30PM</li>
                            <li className="business-hours"><strong>Tuesday:</strong> 8:00AM-6:30PM</li>
                            <li className="business-hours"><strong>Wednesday:</strong> 12:00PM-6:30PM</li>
                            <li className="business-hours"><strong>Thursday:</strong> 12:00PM-6:30PM</li>
                            <li className="business-hours"><strong>Friday:</strong> 12:00PM-6:30PM</li>
                            <li className="business-hours"><strong>Saturday:</strong> 12:00PM-6:30PM</li>
                            <li className="business-hours"><strong>Sunday:</strong> 12:00PM-6:30PM</li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_four footer-links-desc">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><Link to="/" style={{fontWeight: 300}}>Home</Link></li>
                            {/* <li><Link to="/real-estate-window-tinting" style={{fontWeight: 300}}>Residential</Link></li>
                            <li><Link to={"/commercial-window-tinting"} style={{fontWeight: 300}}>Commercial</Link></li> */}
                            <li><Link to="/automative-window-tinting" style={{fontWeight: 300}}>Automotive</Link></li>
                            <li><Link to="/marine-tinting" style={{fontWeight:300}}>Marine Tinting</Link></li>
                            <li><Link to="/tint-removal" style={{fontWeight: 300}}>Tint Removal</Link></li>
                            <li><Link to="/ceramic-glass-coating" style={{fontWeight: 300}}>Ceramic Glass Coating</Link></li>
                            <li><Link to="/contact" style={{fontWeight:300}}>Contact</Link></li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_five footer-location-desc">
                        <h3>Location</h3>
                        <ul>
                            <li>1114N Fancher RD, <br/>
                                Spokane Valley, WA, 99212</li>
                            <li>We are on UNIT 7</li>
                        </ul>
                    </div>
                    <div className="footer_detail footer_detail_six footer-location-desc">
                        <h3> <strong>Lic:</strong> <span style={{fontWeight:300}}>FDWINWT761NW</span></h3> 
                    </div>
                </div>
                <div className="footer_copyright_cont">
                    <div className="copyright">
                        <p>©<YearDateUpdate/> FD WINDOW TINT LLC. All Rights Reserved.</p>
                    </div>
                    <div className="footer-designed-by">
                        <p style={{fontWeight: 500}}> Designed by <Link to="https://mansionarts.net/" target="_blank" style={{fontFamily: 'Montserrat', fontWeight: 500}}>Mansion Arts Ltd.</Link></p>   
                    </div>
                    <div className="footer_socials">
                        <ul>
                            <li><Link to="https://www.facebook.com/profile.php?id=61562367089224" target="_blank"><AiFillFacebook/></Link></li>
                            <li><Link to="https://www.instagram.com/fd_window_tint/" target="_blank"><AiFillInstagram/></Link></li>
                            {/* <li><Link to="#" target="_blank"><AiFillTwitterSquare/></Link></li> */}
                            <li><Link to="https://wa.link/hckdpq" target="_blank"><FaWhatsappSquare/></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
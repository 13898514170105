import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/solar-control-film.webp';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './corporate-commercial-department.css';

const SolarControlFilms = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Solar Control Films" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Commercial</Link> / Solar Control Films
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <div className="solorcontrol-desc">
                                <h1>
                                    Solar Control Films
                                </h1>
                                <br />
                                <p>
                                    Maintaining solar control is crucial in office and retail environments to diminish glare and ensure effective heat rejection for proper building temperature regulation. Glare reduction offers advantages across different spaces by shielding employees' eyes from sunlight and preventing glare on electronic screens.  
                                </p>
                                <p>
                                    Sun Control Window Films, thin and resilient, facilitate improved interior temperature management by mitigating intense light and decreasing hot spots.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default SolarControlFilms
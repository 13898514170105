import { useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
// import { heroMotionEffect } from '../heroMotionEffect';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import ImageOne from '../images/fdee-hero-images/windows-tinting7.webp';
import ImageTwo from '../images/fdee-hero-images/windows-tinting8.webp';
import ImageThree from '../images/fdee-hero-images/windows-tinting9.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import {motion as m} from 'framer-motion';
import '../pages/home/home.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Homehero = () =>{

    // useEffect(()=>{
    //     heroMotionEffect('HeroText');
    // });

    return (
        <>
            <div className="home-hero">
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={true} // should stop playing on user interaction
                    interval={6000}
                >
                    <div className='hero-section'>
                            <img src={ImageOne} alt="" className='hero-image' />
                            <div className="hero-section-cont">
                                <div id='HeroText' className="hero-text">
                                    <div className="hero-text-detail">
                                        <p style={{fontWeight:'100'}}> 
                                            Automotive | Tint Removal | Ceramic Glass Coating 
                                        </p>
                                        <h3>Spokane Valley, Washington</h3>
                                        <h3>Window Tinting Services</h3>
                                    </div>
                                </div>
                                <Link to='/contact' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Get in touch</Link>
                            </div>
                            <div className="image-overlay"></div>
                    </div>

                    <div className='hero-section'>
                            <img src={ImageTwo} alt="" className='hero-image' />
                            <div className="hero-section-cont">
                                <div id='HeroText' className="hero-text">
                                    <div className="hero-text-detail">
                                        <p style={{fontWeight:'100'}}> 
                                            Ceramic Glass Coating | Marine Tinting | Automotive
                                        </p>
                                        <h3>Proffesional</h3>
                                        <h3>Window Tint Installers</h3>
                                    </div>
                                </div>
                                <Link to='/contact' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Get in touch</Link>
                            </div>
                            <div className="image-overlay"></div>
                    </div>
    
                    <div className='hero-section'>
                            <img src={ImageThree} alt="" className='hero-image' />
                            <div className="hero-section-cont">
                                <div id='HeroText' className="hero-text">
                                    <div className="hero-text-detail">
                                        <p style={{fontWeight:'100'}}> 
                                            Automotive | Tint Removal | Ceramic Glass Coating 
                                        </p>
                                        {/* <h3>24/7</h3> */}
                                        <h3>Window Tinting Services</h3>
                                    </div>
                                </div>
                                <Link to='/contact' className='btn sm contact-cta-btn hero-btn cta-btn-hero'>Get in touch</Link>
                            </div>
                            <div className="image-overlay"></div>
                    </div>

                </AutoplaySlider>
            </div>
        </>
    );
}


export default Homehero
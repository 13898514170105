
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/home/Home';
import About from './pages/about/About';
import RealEstateWindowTinting from './pages/realestate-window-tinting/RealestateWindowTinting';
import AutomativeWindowTinting from './pages/automative-window-tinting/AutomativeWindowTinting';
import CommercialWindowTinting from './pages/commercial-window-tinting/CommercialWindowTinting';
import AntiGraffitiFilms from './pages/anti-graffiti-films/AntiGraffitiFilms';
import DecorativeWindowTint from './pages/decorative-window-tint/DecorativeWindowTint';
import LowEFilms from './pages/low-e-films/LowEFilms';
import PrivacyWindowFilmsCasperCloaking from './pages/privacy-window-films-casper-cloaking/PrivacyWindowFilmsCasperCloaking';
import SafetyFilms from './pages/safety-films/SafetyFilms';
import SecurityFilmsShatterResistance from './pages/security-films-shatter-resistance/SecurityFilmsShatterResistance';
import SolarControlFilms from './pages/solar-control-films/SolarControlFilms';
import MarineTinting from './pages/marine-tinting/MarineTinting'
import CeramicGlassCoating from './pages/ceramic-glass-coating/CeramicGlassCoating';
import TintRemoval from './pages/tint-removal/TintRemoval';
import Team from './pages/team/Team';
import Contact from './pages/contact/Contact';
import Footer from './components/Footer';
import Gallery from './pages/gallery/Gallery';
import FloatingSquareAppointmentsButton from './components/FloatingSquareAppointmentsButton';
import WhatsAppUs from './components/WhatsAppUs';


const App = () =>  {
  return (
    <BrowserRouter>
      <NavBar/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="team" element={<Team/>} />
          <Route path="contact" element={<Contact />} />
          <Route path="anti-graffiti-films" element={<AntiGraffitiFilms/>} />
          <Route path="decorative-window-tint" element={<DecorativeWindowTint/>} />
          <Route path="low-e-films" element={<LowEFilms/>} />
          <Route path="privacy-window-films-casper-cloaking" element={<PrivacyWindowFilmsCasperCloaking/>} />
          <Route path="security-films-shatter-resistance" element={<SecurityFilmsShatterResistance/>} />
          <Route path="saftey-films" element={<SafetyFilms/>} />
          <Route path="solar-control-films" element={<SolarControlFilms/>} />
          <Route path='real-estate-window-tinting' element={<RealEstateWindowTinting/>}/>
          <Route path="commercial-window-tinting" element={<CommercialWindowTinting/>}/>
          <Route path="marine-tinting" element={<MarineTinting/>} />
          <Route path='ceramic-glass-coating' element={<CeramicGlassCoating/>}/>
          <Route path="tint-removal" element={<TintRemoval/>}/>
          <Route path="automative-window-tinting" element={<AutomativeWindowTinting/>} />
          <Route path='gallery' element={<Gallery/>}/>
        </Routes>
      <NavBar/>
      <Footer/>
      <FloatingSquareAppointmentsButton/>
      <WhatsAppUs/>
    </BrowserRouter>
  );
}

export default App;

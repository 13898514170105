import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import PageHeaderImage from '../../images/fdee-hero-images/privacy-films.webp';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './criminallaw.css';

const PrivacyWindowFilmsCasperCloaking = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Privacy Window Films & Casper Cloaking" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Commercial</Link> / Privacy Window Films & Casper Cloaking
                </p>
            </PageHeader>
            <section className='services'>

                <div className="residential-pest-control-services">
                    <div className="container residential-pest-control-content-container">
                        <div className="residential-pest-control-content">
                            <h2>
                                Privacy Window Films & Casper Cloaking
                            </h2>
                            <br />
                            <p>
                                Privacy films obscure the view through glass windows, providing a simple solution to minimize the risk of visual hacking and establish comfortable environments in offices or retail outlets. Conversely, Casper Cloaking obstructs the visibility of electronic screens, making it ideal for conference rooms or offices where maintaining visual privacy is essential for business operations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default PrivacyWindowFilmsCasperCloaking
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/marine-tinting.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigationDisputeResolution.css';

const MarineTinting = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Marine Window Tinting" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Marine Window Tinting
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <div className="marine-tinting-text">
                                <h1>
                                    Marine Tinting
                                </h1>
                                <br/>
                                <p>
                                    FD window tint provides cool view protection by adding a permanent barrier between your boat's interior and the destructive effects of the sun. Marine and RV manufacturers are producing Luxury and high-end vessels with higher grade interiors, so it has become increasingly crucial to use window tints protect these interiors from fading and cracking. Boat window tinting will not only protect seats, carpets, vinyl and leather, but your air conditioner won't have to work as hard so you'll save fuel and help extend the life of the air conditioning system.
                                </p>
                                <p>
                                    Our boat windshield tints cut down on potentially dangerous (UV-A and UV-B) rays caused by the sun. UV rays can easily penetrate windows, so in addition to sunscreen, boat window tinting blocks up to 99% of the suns cancer-causing rays, making it a reliable way to reduce your cancer risk.
                                </p>
                                <p>
                                    In an era where health, youth, and appearance is of great importance to many, studies have shown ultraviolet rays to advance our skin’s aging process, and to cause melanoma, a potentially deadly form of skin cancer. Additionally, boat window tinting cuts potentially dangerous glare from the water, reflective surfaces, and from other vessels’ lights at night.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default MarineTinting
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/home-tinting2.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './legal-advisory-consultancy-department.css';

const RealEstateWindowTinting = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Residential Window Tinting" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Residential
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <p>If you find yourself avoiding specific rooms in your house due to uncomfortable warmth, particularly in the warmer seasons, it's time to restore livability to those spaces. While curtains and blinds offer privacy and some heat and solar energy reduction, they often do so at the expense of natural light.</p>

                            <p>Window Films effectively eliminate hot spots while maintaining the view, ensuring that every seat in your home remains inviting.</p>

                            <p>Preserve the appearance of your furniture, walls, art, hardwood floors, and window treatments by preventing harmful UV rays from causing fading and damage to your belongings. Continuous exposure to ultraviolet light can lead to the loss of color and value in your interior surfaces and items.</p>

                            <p>Utilizing Window Films enables you to showcase your art, photos, and decorative pieces proudly, eliminating concerns about sun damage and deterioration.</p>
                            <br/>
                            <h2>
                                Lower Energy Costs
                            </h2>
                            <p>
                                Heat rejection can lead to significant energy savings on your utility bills, cutting down cooling expenses by as much as 30%, particularly during  sweltering summers. Provide relief to both your cooling system and your finances with Sun Control Window Films. 
                            </p>
                            <p>
                                Maintain a cool and comfortable environment throughout the day by blocking out up to 78% of the sun's heat entering through your windows.
                            </p>
                            <h2>
                                Glare Reduction
                            </h2>
                            <p>We're familiar with the bothersome glare in the afternoon that leads to discomfort, particularly when it reflects on your television or other electronic gadgets. Utilizing a window film allows you to bask in natural light while reading, spending quality time with family, or watching TV, all without experiencing eyestrain due to glare.</p>

                            <p>Clear visibility enhances productivity whether you're working or enjoying leisure activities on a laptop, computer, or other mobile devices. Embrace every corner of your home from dawn until dusk.</p>
                            <h2>
                                Improve Safety & Security
                            </h2>
                            <p>
                                From break-ins to accidents, Window Films provide discreet protection against various dangers. Enhance the safety of your home and windows without compromising their appearance with Safety & Security Window Films. These security films reinforce windows, making them more resistant to penetration, and help keep glass fragments intact after impacts from break-ins, natural disasters, or other incidents like stray baseballs.
                            </p>
                            <h2>
                                Enhance Privacy & Style
                            </h2>
                            <p>
                                Achieve both privacy and aesthetic appeal in your home without compromising on natural light. Elevate your privacy while personalizing your living space with Decorative Window Films. Perfect for use in foyers, entryways, partitions, shower doors, bathroom windows, verandas, and other glass surfaces. These films offer a diverse range of patterns, from subtle to striking design choices.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default RealEstateWindowTinting
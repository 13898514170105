import './yeardateUpdate.css'

const YearDateUpdate = () => {
    return (
        <>
            <span className="year-date">
                {new Date().getFullYear()}
            </span>
        </>
    )
}

export default YearDateUpdate
import { FaSun } from "react-icons/fa";
import { GiSunglasses } from "react-icons/gi";
import { GiAmericanShield } from "react-icons/gi";
import { TbUvIndex } from "react-icons/tb";
import { MdRollerShades } from "react-icons/md";



export const serviceslinks = [
    {
        name: "Solar Control Films",
        path: '/solar-control-films',
        cName: 'dropdown-link'
    },
    {
        name: "Privacy Window Films & Casper Cloaking",
        path: '/privacy-window-films-casper-cloaking',
        cName: 'dropdown-link'
    },
    {
        name: "Decorative Window Tint",
        path: '/decorative-window-tint',
        cName: 'dropdown-link'
    },
    {
        name: "Safety Films",
        path: '/saftey-films',
        cName: 'dropdown-link'
    },
    {
        name: "Anti-Graffiti Films",
        path: '/anti-graffiti-films',
        cName: 'dropdown-link'
    }
]


export const depertmentslinks = [
    {
        name: "Lower Energy Costs",
        path: '/litigation-dispute-resolution-department',
        cName: 'dropdown-link'
    },
    {
        name: "Glare Reduction",
        path: '/corporate-commercial-department',
        cName: 'dropdown-link'
    },
    {
        name: "Improve Safety & Security",
        path: '/improve-safety-security',
        cName: 'dropdown-link'
    },
    {
        name: "Enhance Privacy & Style",
        path: '/enhance-privacy-style',
        cName: 'dropdown-link'
    }
]


const Car1 = require('./images/cars/car1.webp');
const Car2 = require('./images/cars/car2.webp');
const Car3 = require('./images/cars/car3.webp');
const Car4 = require('./images/cars/car4.webp');
const Car5 = require('./images/cars/car5.webp');
const Car6 = require('./images/cars/car6.webp');


export const testimonials = [
    {
        id: 1,
        name: "Sedan",
        avatar: require('./images/cars/car3.webp')
    },
    {
        id: 2,
        name: "Coupes",
        avatar: require('./images/cars/car1.webp')
    },
    {
        id: 3,
        name: "Small SUVs'",
        avatar: require('./images/cars/car2.webp')
    },
    {
        id: 4,
        name: `Large SUVs'`,
        avatar: require('./images/cars/car4.webp')
    },
    {
        id: 5,
        name: "Trucks",
        avatar: require('./images/cars/car5.webp')
    },
    {
        id: 6,
        name: "Windshield",
        avatar: require('./images/cars/car6.webp')
    }
]

// const serviceOne = require('../src/images/residential.jpg');
// const serviceTwo = require('../src/images/commercial.jpg');
// Home services carousel data




export const homeCarouselServices = [
    {
        id: 1,
        img: require('../src/images/fdee-hero-images/home-tinting.png'),
        name: 'Residential Window Tinting',
        description: `Avoiding specific rooms in your house due to uncomfortable warmth? It's time to restore livability to those spaces.`,
        path: '/residential-window-tinting'
    },
    {
        id: 2,
        img: require('../src/images/slide02.jpg'),
        name: 'Commercial Window Tinting',
        description: `Our services for commercial window tinting offer significant advantages that may go beyond what many people recognize.`,
        path: '/commercial-window-tinting'
    },
    {
        id: 3,
        img: require('../src/images/fdee-hero-images/windows-tinting9.webp'),
        name: 'Automotive Window Tinting',
        description: `Automotive Window Tint plays a crucial role in maintaining the integrity of your car's interior and contributing to the vehicle's overall long-term value.`,
        path: '/automotive-window-tinting'
    },
    {
        id: 4,
        img: require('../src/images/fdee-hero-images/marine-tinting.jpg'),
        name: 'Marine Tinting',
        description: `FD window tint provides cool view protection by adding a permanent barrier between your boat's interior and the destructive effects of the sun.`,
        path: '/marine-tinting'
    },
    {
        id: 5,
        img: require('../src/images/fdee-hero-images/tint-removal.jpg'),
        name: 'Tint Removal',
        description: `Unless you already have experience working with window tint film, it is generally going to be a good idea to let a professional help you remove your car window tint.`,
        path: '/tint-removal'
    },
    {
        id: 6,
        img: require('../src/images/fdee-hero-images/ceramic-glass-coating.jpg'),
        name: 'Ceramic Glass Coating',
        description: `Is a liquid polymer that is applied by hand to the window of a vehicle. The coating chemically bonds with the vehicle’s factory glass, creating a layer of protection.`,
        path: '/ceramic-glass-coating'
    }
]



// Benefits icon



export const benefitsCarousel = [
    {
        id: 1,
        icon: <FaSun />,
        name: 'Solar Heat Control Window Films',
    },
    {
        id: 2,
        icon: <GiSunglasses />,
        name: 'Glare Control Window Films',
    },
    {
        id: 3,
        icon: <GiAmericanShield />,
        name: 'Saftey and Control Window Films',
    },
    {
        id: 4,
        icon: <TbUvIndex />,
        name: 'Uv and Fade Control Window Films',
    },
    {
        id: 5,
        icon: <MdRollerShades />,
        name: 'Privacy/View Control Window Films',
    }
]

// Our Team
// img: require('../src/images/team/shisanya.webp'),

export const teamCarouselData = [
    {
        id: 3,
        name: 'Mr. EDWIN SHISANYA AMBOSO – Founding Partner',
        description: `Mr. Edwin Shisanya is the Managing Partner and Head of the Litigation Department at the firm. He is an Advocate of the High Court of Kenya having obtained his Bachelor of Laws Degree from Moi University and a Post Graduate Diploma from the Kenya School of Law. 
        He is presently pursuing his Master of Laws Degree at The University of Nairobi, Kenya. He is an astute, methodical and intrepid litigation lawyer with a specific bias in the areas of Employment Law, Contract law, Land Law, Constitutional Law and Civil Claims Litigation. 
        He possesses a sound understanding of the litigation environment and offers firm representation to clients. 
        Mr. Shisanya has represented a wide range of clients including but not limited to Tana River County Government, Kenya Human Rights Commission, Mumias Outgrowers Company (1998) Limited, Sheria Sacco Society Limited. He is also a top consultant for a number of law firms and companies. 
        His success in practice is attributed to a wide array of soft skills like effective communication, interpersonal skills, integrity, great research skills, ethics, negotiation, planning, organization and execution. 
        Prior to the inception of Shisanya & Company Advocates, Mr. Shisanya previously worked as a Legal Assistant at the firm of Kamau Kuria & Company Advocates and as an Associate Advocate at Saluny Advocates LLP. He is a member of the Law Society of Kenya, the East African Law Society as well as the Machakos Golf Club.`,
        path: '/commercial-corporate-law'
    },
    {
        id: 4,
        name: 'Mr. FERNADOS MANG’ATE OMBASO – Associate Advocate',
        description: `Mr. Ombaso is an Advocate of the High Court of Kenya. He is a holder of a Bachelor of Laws (LL.B.) degree from Moi University having graduated in the year 2018 and an Associate of the Chartered Institute of Arbitrators (CIArb - K). 
        He is trained in Credit Management with the Kenya School of Credit Management (a KASNEB approved and supervised course) and has several other trainings in loan management and debt recoveries. Mr. Ombaso is an ambitious and dedicated person who has wide knowledge on banking, property, human rights, governance, land and conveyancing matters. 
        He has over the course of his legal career provided legal counsel to several clients and corporate entities. He is a quick learner who can quickly adjust to any work environment on demand. This attribute has made it possible for him to be a lead consultant in several projects by different organizations. 
        Mr. Ombaso is well versed in developing policies, work plans, handbooks, projects and policies required by corporate clients to realize their objectives. He thrives in a dynamic environment and quickly adapts to the ever-changing demands of the legal field. He is also well versed in trial preparation, litigation, research and drafting legal documents. 
        With this legal background, Ombaso has gathered experience working with various non-governmental organizations in litigation, developing polices, strategic documents and other governance documents.`,
        // title: 'A Company',
        path: '/criminal-law'
    },
    {
        id: 5,
        name: 'Ms. IVY NAFULA WANGUSI – Senior Associate',
        description: `Ms Nafula is a Senior Associate at the firm. She is an Advocate of the High Court of Kenya with over 4 years’ experience in legal practice. She holds a Bachelor of Laws degree from Moi University as well as a Post-Graduate Diploma from the Kenya School of Law and is a member of the Law Society of Kenya. 
        Ms Nafula has extensive practical expertise following her diverse working experience. She has appeared in many high profile and complex legal matters in the Court of Appeal, High Court, Industrial Courts, Magistrate Courts and Specialized Tribunals. She is also an ardent researcher. Ms. Nafula is the head of Real Estate and Property Law department. 
        In the property law field, her work entails advising clients on property and real estate matters; preparing documents for various property acquisitions; development and disposals; resolving disputes and conducting due diligence. Ms. Nafula has previously worked as an Associate Advocate at TJ Michael & Company Advocates and at Kiamah Kibathi & Company Advocates LLP. 
        She also worked as a legal assistant at Magare Musundi Advocates.`,
        // title: 'A Company',
        path: '/judicial-review-constitutional-law'
    },
    {
        id: 6,
        name: 'Ms. EMMACULATE HYIMO – Associate Advocate',
        description: `Ms. Hyimo is an Associate Advocate at the firm holding a postgraduate diploma from The Kenya School of Law. She boasts of great experience in General Litigation, Dispute Resolution (ADR) as well as contract drafting, review and negotiations. Currently, Ms. Hyimo is the head of the Alternative Dispute Resolution department at the firm.`,
        // title: 'A Company',
        path: '/alternative-dispute-resolution'
    },
    {
        id: 7,
        name: 'Mr. MUGARO MARTIN – Associate Advocate',
        description: `Mr. Mugaro is an Associate Advocate at the firm having successfully completed his post-graduate studies at the Kenya School of Law and been admitted to the Kenyan Bar. Mr. Mugaro has a special interest in the area of Tax Law and Arbitration. At the moment, Mr. Mugaro is attached to the Litigation department under the leadership of the firm’s Managing Partner. He previously worked as a legal intern at the firm of Kitiwa & Company Advocates.`,
        // title: 'A Company',
        path: '/conveyancing-real-estate'
    },
    {
        id: 8,
        name: 'Ms. Frankline Ingado Samena',
        description: `Ms. Frankline Samena is a Lawyer currently awaiting Admission to the Bar having graduated from The Nairobi University, School of Law and completing her Post Graduate Diploma in Law at the Kenya School of Law. She worked in administration and customer relations before embarking on her legal career, showcasing versatility and adaptability. 
        She previously worked at Saluny Advocates LLP as a legal assistant and Ngwele Company LLP as a pupil. Frankline has a keen interest in employment and labour law practice, regulatory and compliance affairs, legal research and writing, legal consultancy and advisory services.`
    }
]

// Our Consultants

export const consultantsCarouselData = [
    {
        id: 3,
        // img: require('../src/images/team/Edwin.webp'),
        name: 'Mr. Edwin K. Saluny, LL. B, Hons (Moi University), LLM – Candidate (Strathmore University) – Consultant',
        description: `Mr. Saluny has in-depth knowledge of the workings and legal requirements of government agencies, public and private companies, financial institutions, and international companies.`
    },
    {
        id: 3,
        // img: require('../src/images/team/Wairimu.webp'),
        name: 'Mrs. Cuna Wairimu, LL.B, Hons (Moi University), CPS (ICPAC) – Consultant',
        description: `Mrs. Cuna has over eight (8) years of experience and knowledge in Civil and Commercial Law practice and legal research.`
    },
    {
        id: 3,
        img: require('../src/images/avatar1.png'),
        name: 'Mrs. Majimbo Georgiadis, Advocate – Consultant',
        description: `Lorem ipsum`,
    }
]


// Testimonials section data

// export const testimonials = [
//     {
//         id: 1,
//         name: 'Ms. Jon Doe',
//         description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, corporis?',
//         // title: 'A Company',
//         img: require('../src/images/avatar1.png')
//     },
//     {
//         id: 2,
//         name: 'Jon Doe',
//         description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, corporis?',
//         // title: 'B Company',
//         img: require('../src/images/avatar1.png')
//     },
//     {
//         id: 3,
//         name: 'Jon Doe',
//         description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, corporis?',
//         // title: 'C Company',
//         img: require('../src/images/avatar1.png')
//     },
//     {
//         id: 4,
//         name: 'Jon Doe',
//         description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi, corporis?',
//         // title: 'D Company',
//         img: require('../src/images/avatar1.png')
//     }
// ];

// FAQS

export const faqs = [
    {
        id: 1,
        question: "What pests do you specialize in controlling?",
        answer: `At PestEX Masters, we specialize in the control and eradication of a wide range of pests, including but
        not limited to cockroaches, termites, bed bugs, ants, mosquitoes, rats, mice, fleas, and spiders. Our
        experienced technicians are trained to handle various pest infestations with efficiency and effectiveness.`
    },
    {
        id: 2,
        question: "Are your pest control methods safe for my family and pets?",
        answer: `Absolutely! We prioritize the safety of your family, pets, and the environment. PestEX Masters utilizes
        eco-friendly and approved pest control products that are safe for humans and pets. Our team follows
        strict guidelines to ensure that all treatments are applied in a manner that minimizes any potential risks
        to your loved ones.`
    },
    {
        id: 3,
        question: "How long does a typical pest control treatment take?",
        answer: `The duration of a pest control treatment varies depending on the nature and severity of the
        infestation. For common household pest problems, treatments usually take anywhere from 1 to 2 hours.
        However, for more complex cases or larger properties, the timeframe may be longer. Our team will
        provide you with an estimated time frame during the initial inspection.`
    },
    {
        id: 4,
        question: "Do I need to vacate my premises during the pest control treatment?",
        answer: `In most cases, you and your family do not need to leave your premises during the treatment.
        However, we recommend that pregnant women, infants, and individuals with respiratory conditions or
        sensitivities stay away from the treated areas until the products have dried. Our technicians will provide
        you with specific instructions tailored to your situation.`
    },
    {
        id: 5,
        question: "How often should I schedule pest control services?",
        answer: `The frequency of pest control services depends on various factors, such as the pest type, severity of
        the infestation, and your property&#39;s susceptibility to pests. For preventive measures, we recommend
        regular treatments every 3 to 6 months. However, for more severe infestations, our technicians will
        assess the situation and recommend a suitable treatment plan.`
    },
    {
        id: 6,
        question: "Can I book a one-time pest control service or do I need a contract?",
        answer: `At PestEX Masters, we provide both one-time pest control services and long-term contracts tailored
        to your needs. If you have a specific pest problem you&#39;d like to address, you can opt for a single
        treatment. For comprehensive and ongoing pest management, we offer contract options that include
        regular inspections and treatments to ensure your property remains pest-free.`
    },
    {
        id: 7,
        question: "How can I prevent future pest infestations?",
        answer: `Along with our professional pest control services, we believe in empowering our customers with
        preventive measures. Our technicians will provide you with personalized recommendations to help
        prevent future pest infestations. These may include sealing entry points, maintaining cleanliness, storing
        food properly, and implementing appropriate landscaping practices. Regular inspections and proactive
        measures can significantly reduce the risk of pests returning.`
    }
]
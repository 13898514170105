import "./floatingsquareappointmentsbutton.css"; // Import the CSS file

const FloatingSquareAppointmentsButton = () => {
return (
    <a
    target="_blank"
    className="floating-button"
    href="https://squareup.com/appointments/book/bhh3v2da7let5e/LHATFJM17N867/start"
    rel="nofollow"
    >
    Book Now
    </a>
);
};

export default FloatingSquareAppointmentsButton;
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/slide02.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './judicialreviewconstitutionallaw.css';

const CommercialWindowTinting = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Commercial Window Tinting" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Commercial
                </p>
            </PageHeader>
            <section className='services'>

                <div className="consultancy-training-services">
                    <div className="container consultancy-training-content-container">
                        <div className="consultancy-training-content">
                            <div className="commercial-desc">
                                <h2>
                                    Advantages of Commercial Window Tinting
                                </h2>
                                <br />
                                <p>
                                    Our services for commercial window tinting offer significant advantages that may go beyond what many people recognize. The benefits begin with enhancing energy efficiency, leading to substantial savings on cooling expenses. Often overlooked, glare reduction is another valuable perk, creating more comfortable and productive indoor spaces by eliminating bothersome glare. The appropriate commercial tinting film can also contribute to a company's aesthetics, providing increased privacy and enhancing the overall appearance of a building. Additionally, security film becomes a valuable asset for retail establishments, boosting business safety and security. Whether it's cutting cooling costs or safeguarding a building's furnishings and equipment, Fdee Window Tint delivers genuine value for commercial clients.
                                    Fdee Window Tint provides enduring value and aesthetic appeal through the use of high-quality commercial window films. We provided various tints, they include:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default CommercialWindowTinting
import { useState} from 'react';
import {Link} from 'react-router-dom';
import {GiHamburgerMenu} from 'react-icons/gi';
import {IoCloseOutline} from 'react-icons/io5';
import {MdOutlineArrowDropDown} from 'react-icons/md';
import Logo from '../images/FD-window-tint-logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Dropdown from './Dropdown';
// import DropdownTwo from './DropdownTwo'
import './navbar.css';

const Navbar = () => {

    // navbar color on scroll
    const [color, setColor] = useState(false)

    const navColor = () =>{
        if(window.scrollY >= 280){
            setColor(true)
        }
        else{
            setColor(false)
        }
    }

    // if(window.scrollY >= 90 * window.innerHeight / 90 ){

    window.addEventListener('scroll', navColor)

    // Drop down events
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);    
    const [dropdowntwo, setDropdownTwo] = useState(false); 


    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


    const onMouseEnter = () => {
        if(window.innerWidth < 960){
            setDropdown(false);
        }else{
            setDropdown(true);
        }
    };


    const onMouseLeave = () => {
        if(window.innerWidth < 960){
            setDropdown(false);
        }else{
            setDropdown(false);
        }
    };


    const handleTouchStart = () => {
        if(window.innerWidth < 960){
            setDropdown(true);
        }
    }
    
    const handleTouchEnd = () => {
        if(window.innerWidth < 960){
            setDropdown(true);
        }
    }

    const onMouseEnterTwo = () => {
        if(window.innerWidth < 960){
            setDropdownTwo(false);
        }else{
            setDropdownTwo(true);
        }
    };


    const onMouseLeaveTwo = () => {
        if(window.innerWidth < 960){
            setDropdownTwo(false);
        }else{
            setDropdownTwo(false);
        }
    };


    const handleTouchStartTwo = () => {
        if(window.innerWidth < 960){
            setDropdownTwo(true);
        }
    }
    
    const handleTouchEndTwo = () => {
        if(window.innerWidth < 960){
            setDropdownTwo(true);
        }
    }


    return (
        <>
            <nav className={color ? 'navbar nav-bg' : 'navbar'}>
                <Link to='/' className='logo'>
                    <img src={Logo} alt="fdee-logo" />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <span className='burgerCloseMenu'>
                        {click ? <IoCloseOutline className='close-open-btn'/> : <GiHamburgerMenu className='close-open-btn'/>}
                    </span>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='./' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Home
                        </Link>
                    </li>
                    {/* <li className='nav-item'>
                        <Link to='/#' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            About us
                        </Link>
                    </li> */}
                    <li className='nav-item services-nav-item'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        <Link to='/commercial-window-tinting' className='nav-links commercial-link'
                            onClick={closeMobileMenu}
                        >
                            Commercial<MdOutlineArrowDropDown className='drop-down-arrow'/>
                        </Link>
                        {dropdown && <Dropdown/>}
                    </li> 
                    <li className='nav-item services-nav-item'
                        onMouseEnter={onMouseEnterTwo}
                        onMouseLeave={onMouseLeaveTwo}
                        onTouchStart={handleTouchStartTwo}
                        onTouchEnd={handleTouchEndTwo}
                    >
                        <Link to='/real-estate-window-tinting' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Residential
                        </Link>
                        {/* {dropdowntwo && <DropdownTwo/>} */}
                    </li>
                    <li className='nav-item'>
                        <Link to='/automative-window-tinting' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Automotive
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/marine-tinting' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Marine Tinting
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/tint-removal' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Tint Removal
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/ceramic-glass-coating' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Ceramic Glass Coating
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Contact us
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar
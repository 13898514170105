import { useState, useEffect  } from 'react';
import {ImQuotesLeft} from 'react-icons/im';
import Card from './Card';
// import avatar from '/images/avatar7.png';
import {AiFillLeftSquare} from 'react-icons/ai';
import {AiFillRightSquare} from 'react-icons/ai';
import {testimonials} from '../data';
import '../pages/home/home.css';




const Carsimulator = () => {

    const[index, setIndex] = useState(0);
    const {name, quote, job, avatar} = testimonials[index];

    const prevTestimonialHandler = () => {
        setIndex(prev => prev - 1);
        if(index <= 0){
            setIndex(testimonials.length - 1);
        }
    }


    const nextTestimonialHandler = () => {
        setIndex(prev => prev + 1);

        if(index >= testimonials.length - 1){
            setIndex(0);
        }
    }

    return (
        <section className='testimonials'>
            <div className="container testimonials_container">
                <Card className="testimonial">
                    <div className="testimonial_avatar">
                        <img src={avatar} alt={name} />
                    </div>
                    <div className="testimonial_desc">
                        <h3>{name}</h3>
                    </div>
                </Card>
                <div className="testimonial_btn_container">
                    <button className="testimonial_btn" onClick={prevTestimonialHandler}><AiFillLeftSquare/></button>
                    <button className="testimonial_btn" onClick={nextTestimonialHandler}><AiFillRightSquare/></button>
                </div>
            </div>
        </section>
    )
}

export default Carsimulator
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './conveyancing-real-estate-department.css';

const SecurityFilmsShatterResistance = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Security Films & Shatter Resistance" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Commercial</Link> / Security Films & Shatter Resistance
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h2>
                                Security / Safety Films
                            </h2>
                            <p>
                                Backed by evidence,security films are designed to postpone forced entry by integrating Safety and Security Window Film.
                                <br/>
                                If your business is grappling with intrusions and break-ins, implementing a security window system presents a straightforward method to discourage external threats.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default SecurityFilmsShatterResistance
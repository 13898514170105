import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/tint-removal.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigationDisputeResolution.css';

const TintRemoval = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Tint Removal" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Tint Removal
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <div className="tint-removal">
                                <h1>
                                    Tint Removal
                                </h1>
                                <br/>
                                <p>Unless you already have experience working with window tint film, it is generally going to be a good idea to let a professional help you remove your car window tint. For one, it’ll save you a lot of time and hassle. Removing car tint can be a big project, especially if you are removing it from multiple windows, and it’s one that tends to take a lot of time and elbow grease. Utilizing a window tint removal service is a much more convenient option.</p>
                                <br />
                                <p>Perhaps the biggest reason why you should consider professional window tint removal is the possibility of damaging your vehicle. You might not be that worried about damaging the window tint film that you are removing, but the window beneath is a different story. If it isn’t done just right, the process of removing window tint can easily scratch or break your car’s windows – and the cost to replace a windshield, side window, or rear window can be several hundred dollars or more depending on your vehicle.</p>
                                <br />
                                <p>Along with removing your old window film, a window tint service will also be able to help you choose and install a new film. If you are planning on upgrading to a higher-quality window tint, using the same service to remove your old tint and install the new tint offers even more time-saving convenience. It may help you save some money as well since many window tint services offer discounted rates for window tint removal if you are planning to have them install new films.</p>
                                <br />
                                <p>The final reason why you may want to think twice about removing window tint yourself is safety. Removing window tint involves working with sharp objects such as razor blades and can also produce a lot of dust and debris that isn’t safe to inhale. For this reason, you will need to wear protective gear such as safety gloves, a facemask, and safety glasses in addition to being careful and cautious throughout the removal process.</p>
                                <br />
                                <p>Of course, we don’t want to discourage anyone from taking on new projects, and if you are someone who has a talent and appreciation for that sort of thing then the DIY route may be a fine option for you. For most people, however, working with a professional window tint service to remove old window film is going to be the best option.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default TintRemoval
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion as m } from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import PageHeaderImage from '../../images/fdee-hero-images/contact-header.jpg';
import PageHeader from '../../components/PageHeader';
import { MdEmail } from 'react-icons/md';
import { RiWhatsappFill } from 'react-icons/ri';
import SquareUpAppointments from '../../components/SquareUpAppointments';
import './contact.css';

const Contact = () => {
    const form = useRef();
    const [status, setStatus] = useState('');

    const sendEmail = async (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);

        try {
            const response = await fetch('https://fdwindowtint.com/contact/sendEmail.php', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (result.status === 'success') {
                alert("SUCCESS!");
                form.current.reset();
            } else {
                alert('FAILED...');
            }
        } catch (error) {
            alert('FAILED... ' + error.message);
            form.current.reset();
        }
    };

    return (
        <m.div
            initial={{ x: "-100%" }}
            animate={{ x: "0%" }}
            transition={{ duration: 0.9, delay: 0.7 }}
            exit={{ opacity: 1 }}
        >
            <ScrollToTop />
            <PageHeader title="Contact" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Contact
                </p>
            </PageHeader>
            <section className="contact">
                <div className="container contact_container">
                    <div className="form_wrapper">
                        <h4>Get In Touch</h4>
                        <form ref={form} onSubmit={sendEmail}>
                            <input type="text" name='name' placeholder='Your full name' required />
                            <input type="text" name='email' placeholder='Your Email' required />
                            <textarea name="message" cols="30" rows="10" placeholder='Your Message' required></textarea>
                            <button className="btn lg contact_form_btn">Send Message</button>
                        </form>
                    </div>
                    <div className="contact_wrapper">
                        <div className="contact_wrapper_contents">
                            <a href="mailto:fdwindowtint@gmail.com" target='_blank' rel='noreferrer noopener'>
                                <MdEmail />
                            </a>
                            <p>Email Us</p>
                        </div>
                        <div className="contact_wrapper_contents">
                            <a href="https://wa.link/hckdpq" target='_blank' rel='noreferrer noopener'>
                                <RiWhatsappFill />
                            </a>
                            <p>WhatsApp Us</p>
                        </div>
                    </div>
                    <div className='contact-pg-square-up'>
                    <SquareUpAppointments/>
                    </div>
                    <div className="contact_map_container">
                        <h4>Location</h4>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2687.3274378693422!2d-117.32747842438218!3d47.65863508438385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549e21e3853b669f%3A0x1b689c0929b88c9!2s114%20N%20Fancher%20Rd%2C%20Spokane%20Valley%2C%20WA%2099212%2C%20USA!5e0!3m2!1sen!2snl!4v1723230836191!5m2!1sen!2snl"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Location"
                        ></iframe>
                    </div>
                </div>
            </section>
        </m.div>
    );
};

export default Contact;

import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/ceramic-glass-coating.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigationDisputeResolution.css';

const CeramicGlassCoating = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Ceramic Glass Coating" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / Ceramic Glass Coating
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">

                        <div className="commercial-pest-control-content">
                            <div className="ceramic-desc">
                                <h1>
                                    What is Ceramic Glass Coating?
                                </h1>
                                <p>
                                    Is a liquid polymer that is applied by hand to the window of a vehicle. The coating chemically bonds with the vehicle’s factory glass, creating a layer of protection. , creating a layer of protection.
                                </p>
                            </div>
                            <div className="ceramic-desc">
                                <h2>
                                    What Ceramic Windsheild Coating Does.
                                </h2>
                                <ul>
                                    <li>
                                        • Greatly improves clarity & visibility extending drivers’ reaction time.
                                    </li>
                                    <li>
                                        • Helps deflect small road debris and minimizes damage to glass.
                                    </li>
                                    <li>
                                        • Significantly reduces glare and light scatter.
                                    </li>
                                    <li>
                                        • Allows dirt, insects, and grime to be easily washed away.
                                    </li>
                                    <li>
                                        • Weather is no match. Snow, ice and sleet is removed with minimal effort.
                                    </li>
                                    <li>
                                        • Helps protect against acid rain, corrosion and mineral buildup.
                                    </li>
                                </ul>
                            </div>
                            <div className="ceramic-desc">
                                <h2>
                                    Advantages of Ceramic Coating
                                </h2>
                                <ul>
                                    <li>
                                        • Clearer windshields mean improved driver reaction time.
                                    </li>
                                    <li>
                                        • Better visibility during inclement weather.
                                    </li>
                                    <li>
                                        • Reduces the likelihood of damage from small road debris.
                                    </li>
                                    <li>
                                        • Allows insects to be easily washed away. 
                                    </li>
                                    <li>
                                        • Makes ice and snow much easier to remove. 
                                    </li>
                                    <li>
                                        • Protects against acid rain, corrosion, and mineral buildup. 
                                    </li>
                                    <li>
                                        • Helps preserve the structural integrity of the factory-installed windshield. 
                                    </li>
                                    <li>
                                        • Keeps glass looking newer longer.
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </m.div>
    )
}

export default CeramicGlassCoating
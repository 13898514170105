import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/pageheader.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './litigation-dispute-resolution-department.css';

const Gallery = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Litigation and Dispute Resolution" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Depertments</Link> / Litigation and Dispute Resolution Department
                </p>
            </PageHeader>
            <section className='services'>

                <div className="commercial-pest-control">
                    <div className="container commercial-pest-control-contcontent-container">
                        <div className="commercial-pest-control-content">
                            <h1>
                                Litigation and Dispute Resolution Department
                            </h1>
                            <br />
                                <p>
                                    The firm through its litigation and dispute resolution department is involved in day-to-day litigation of clients’ matters before all Superior and Subordinate Courts and tribunals. The firm has successfully represented clients in complex litigation and possess a score card to be rivalled. The firm also represents parties in alternative dispute resolution avenues including arbitrations and court annexed mediation. The firm’s Partners are authoritatively known for their litigation experience in a wide range of practice areas that range from inter alia, constitutional law litigation, general civil law litigation, criminal law litigation among others.
                                </p>

                                <p>
                                    Among the notable matters that the department has handled under the leadership of the founding partner include but not limited to; one, litigating a high profile and or landmark case before an empaneled bench of judges of Hon. Justices David Majanja (presiding), Chacha Mwita & Mugure Thande in respect of Petition E90/2022: Okiya Omtata vs AG & others (consolidated) wherein we represented the 11th Interested Party (Legal Aid Clinic). The 1st Petition was premised on the question of misinterpretation and misapplication of Articles 99(3) and 193(3) of the Constitution of Kenya, 2010 in so far as the said articles relate to the barring of any person who fails to satisfy any moral and ethical requirements prescribed in law from vying in elections for state office. 
                                </p>

                                <p>
                                    We also appeared before an empaneled bench of Honorable Justices L. A. Achode, G. V. Odunga, A.K Ndung’u, P. Nyamweya & J.A Makau in Petition Number E291/2020: Leina Konchellah & Mohsen Abdul vs CJ emeritus David K. Maraga (consolidated with Petition No. E317/2020: Edwin K. Saluny vs A. G & others) amongst other petitions. In the instant Petition, we represented the Petitioner in Petition E317/2020 wherein he made a case against the powers of the Chief Justice in advising the President of the Republic of Kenya to dissolve parliament.
                                </p>

                                <p>
                                    We have also appeared before the empaneled bench of Honorable Justices Grace Nzioka (Presiding), J. Wakiaga & E. Maina in respect of Petition E312/2020: Council of Governors vs DPP & others (consolidated with Petition No. 38/2019 Mike Sonko vs DPP & others). We represented the Law Society of Kenya in Petition No. 38 of 2019. The Petition was centered on the applicability of Section 62(6) Anti – Corruption and Economic Crimes Act wherein we argued that the provisions of that Section are contrary to, among other provisions of the Constitution of Kenya, 2010, Articles 10, 27, Chapter Six etc and should be declared unconstitutional.
                                </p>
                        </div>
                    </div>
                </div>

                <div className="container commercial-pest-control-saftey-cont">
                    <ContactCta />
                </div>
            </section>
        </m.div>
    )
}

export default Gallery
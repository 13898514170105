import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/decorative-film.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './alternativedisputeresolution.css';

const DecorativeWindowTint = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title=" Decorative Window Tint" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Commercial </Link> / Decorative Window Tint 
                </p>
            </PageHeader>
            <section className='services'>

                <div className="fumigation-services">
                    <div className="container fumigation-services-content-container">
                        <div className="fumigation-services-content">
                            <h2>
                                Decorative Window Tint
                            </h2>
                            <br/>
                            <p>
                                Enhance your office space by incorporating Glass Finishes, offering a textured appearance at an affordable cost. These decorative films are an ideal solution for introducing depth and vibrancy to conference rooms, lobbies, retail outlets, private offices, and outward-facing windows.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default DecorativeWindowTint
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { FaAward } from "react-icons/fa";
import { GiIonicColumn } from "react-icons/gi";
import { MdOutlineHandshake } from "react-icons/md";
import AboutImage from "../images/pageheader.jpg" 
import '../pages/home/home.css';

const PracticeAreaIntro = () => {

    return (
        <div className="cta">
            <div className="container cta-container cta_cont_practice_area">
                {/* <div className="cta-text-l">
                    <img src={AboutImage} alt="about image" />
                </div> */}
                <div className="cta-text-r">
                    <div className="value value_practice_area">
                        <p>
                        <h2>
                            Professional Window Tinting Services
                        </h2>
                        <br />
                            Investing in window tinting services is a wise decision that can significantly enhance your home, office, or car. Whether you're looking for solar control, security films, or paint protection, we provide top-notch products tailored to your specific tinting requirements. The installation of your window film is crucial, and choosing FD Tinting ensures a job done correctly from the start. Our team of professionals possesses extensive experience, knowledge, and access to industry-leading products, guaranteeing customer satisfaction throughout the entire process, from sales to installation. We are committed to delivering successful solutions for your windows.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticeAreaIntro
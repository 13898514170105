import './squareupappointments.css'

const SquareUpAppointments = () => {
    return (
    <section className="section">
        <div className="content">
        <h2 className="heading">Book Your Appointment Today!</h2>
        <p className="text">
            Get personalized service at your convenience. Click below to schedule
            your appointment with us.
        </p>
        <a
            target="_blank"
            className="button"
            href="https://squareup.com/appointments/book/bhh3v2da7let5e/LHATFJM17N867/start"
            rel="nofollow"
        >
            Book Now
        </a>
        </div>
    </section>
    );
};

export default SquareUpAppointments;
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop';
import {useEffect} from 'react';
import PageHeaderImage from '../../images/fdee-hero-images/saftey-films.jpg';
import PageHeader from '../../components/PageHeader';
import {Link} from 'react-router-dom';
// import HomeServices from '../../components/HomeServices';
import WhyUs from '../../components/WhyUs';
import ContactCta from '../../components/ContactCta';
import FAQS from '../../components/FAQS';
import './conveyancingrealestate.css';

const SafetyFilms = () => {
    return (
        <m.div 
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop />
            <PageHeader title="Safety Films" image={PageHeaderImage}>
                <p>
                    <Link to="/">Home</Link> / <Link to="/services">Commercial</Link> / Safety Films 
                </p>
            </PageHeader>
            <section className='services'>

                <div className="bird-control-container container">
                    <div className="bird-control-content-container">
                        <div className="bird-control-content">
                            <div className="saftey-film-content">
                                <h2>
                                    Safety Films
                                </h2>
                                <br />
                                <p>
                                    Offering an optimal blend of sun control and impact resistance, the installation of safety film can offer reassurance to various business owners. These films shield against impacts stemming from burglaries or harsh weather conditions while also delivering the financial and comfort advantages of regulating temperatures within your commercial establishment.
                                </p>
                                <h2>Boosting Your Protection With Security Film</h2> 
                                <p>
                                    Get a boost in protection with security window film. FD WINDOW TINT provides safety and window film installation services in SPOKANE. We have a large selection of security and safety films for your windows. Safety and security film for windows is an excellent way to provide another layer of protection against break-ins, weather, flying sports balls, and more. If your safety film breaks, it shatters safely by bonding the glass shards. We can install safety and security window film anywhere in your home or office. Contact us today to get an estimate on our security and safety window glass film.
                                </p>
                                <h2>Comprehensive Window Security Film Options</h2>
                                <p> 
                                    We offer safety and security film options in multiple thicknesses for your safety needs. Our security film options range from a 2mm thick film to a 15mm thick film. Our team recommends the minimum thickness films to protect against minor break-ins and the maximum thickness films for optimal protection. We have neutral, clear film options to help block up to 99% of UV rays. Safety and security films are all backed with a factory warranty and include a scratch-resistant coating.
                                </p>
                                <h2>
                                    How Do Safety & Security Window Films Work?
                                </h2>
                                <p>
                                    Safety and security films add another layer of protection to help make windows challenging to break. Windows remain intact during inclement weather, when someone is trying to break in, or when a foreign object hits them. If the glass breaks, the window film safely shatters it by bonding the glass shards together.
                                </p>
                                <h2>
                                    Benefits of Safety & Security Film
                                </h2>
                                <p>
                                    Safety and security films are a positive addition that helps lower the risk of broken windows. Some of the many benefits of safety and security window film include the following:
                                </p>

                                <ul>
                                    <li>-Many of our security film options provide heat reduction properties to regulate your home or office temperature.</li>
                                    <li>-Security film helps prevent glass injuries because it keeps the glass shards in one place if the glass breaks.</li>
                                    <li>-Safety and security films make it difficult for vandals to break glass in a robbery.</li>
                                    <li>-You can choose from various levels of tints to get your chosen level of privacy.</li>
                                    <li>-Our security films block up to 99% of harmful UV rays.</li>
                                    <li>-Our team can quickly install or replace your safety and security film.</li>
                                    <li>-We use a glueless adhesive to ensure cleaning your windows is as easy as possible.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </m.div>
    )
}

export default SafetyFilms